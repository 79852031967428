import React, { FC, useMemo } from 'react';

import { EvPieChart, EvSpinner, PieDatum, SORT_ORDER } from '@evinced-private/ui-common';

import { ERROR_TYPE } from 'src/consts/errors';
import ChartsHelper from 'src/helpers/ChartsHelper';
import { IssueSeverities } from 'src/interfaces/IssuesOverviewResponse';
import { PieSlice } from 'src/types/PieChartTypes';

import pieHelper from '../../helpers/PieHelper';
import SeverityService from '../../services/severities/SeverityService';
import EvChartTableLink from '../common/ev-chart-table-link/EvChartTableLink';
import ChartWrapper from '../data-displays/overview-charts/chart-wrapper/ChartWrapper';
import { ChartsSectionV3 } from '../data-displays/overview-charts/charts-section/ChartsSectionV3';

import { ChartErrorView } from './ChartErrorView';

import './IssuesChartsV3.scss';

interface IIssuesChartsProps {
	totalIssues: number;
	severitiesData: IssueSeverities[];
	onItemClick?: (stateKey: string, stateValue: string) => void;
}

const DEFAULT_CHART_HEIGHT = 340;
const DEFAULT_CHART_WIDTH = 530;
const LABEL_FIELD = 'label';
const PERCENT_FIELD = 'percent';
const SEVERITY = 'Severity';
const ISSUES_PERCENTAGE = 'Issues Percentage';
const ISSUE_TYPE = 'Issue type';
const TOTAL_ISSUES = 'Total issues';
const PAGES_COUNT_FIELD = 'pagesCount';

const IssuesCharts: FC<IIssuesChartsProps> = ({ totalIssues, severitiesData, onItemClick }) => {
	const orderedSeveritiesData = useMemo(() => {
		return severitiesData?.sort((a, b) =>
			SeverityService.sortBySeverity(a.severity, b.severity, SORT_ORDER.DESC)
		);
	}, [severitiesData]);

	const fieldFormatter = ({ key, value }): React.ReactNode => {
		return <EvChartTableLink onClick={() => onItemClick(key, value)} value={value} />;
	};

	const renderSeveritiesDistributionPie = (): JSX.Element => {
		const severitiesDistributionPieData = pieHelper.getSeveritiesDistributionPieDataV2(
			orderedSeveritiesData,
			totalIssues
		);

		let errorType: ERROR_TYPE | null = null;

		if (!severitiesData) {
			errorType = ERROR_TYPE.FAILED;
		}
		if (severitiesDistributionPieData.length === 0) {
			errorType = ERROR_TYPE.NO_DATA;
		}

		const chartTitle = 'Breakdown by issue severity';

		return (
			<ChartWrapper title={`${chartTitle} chart`}>
				<EvPieChart
					className="severities-distribution"
					data={severitiesDistributionPieData}
					title={chartTitle}
					withLegend={false}
					width={!errorType && DEFAULT_CHART_WIDTH}
					height={!errorType && DEFAULT_CHART_HEIGHT}
					withAnimation={false}
					isInteractive={false}
					customTooltip={(context: PieSlice) => {
						return ChartsHelper.renderCustomTooltip(context, severitiesDistributionPieData);
					}}
					headerTooltipProps={{
						tooltipText:
							'The graph indicates the distribution of issues by Severity. Each part of the graph represents the % of issues by their severity.',
						tooltipTitle: `What is ${chartTitle}`,
						tooltipPlacement: 'right'
					}}
					tableProps={{
						columnsPattern: [
							{
								name: SEVERITY,
								field: LABEL_FIELD,
								formatter: (cell: string) => fieldFormatter({ key: 'severity', value: cell })
							},
							{ name: ISSUES_PERCENTAGE, field: PERCENT_FIELD },
							{ name: TOTAL_ISSUES, field: PAGES_COUNT_FIELD }
						]
					}}
					onClick={(pieSlice: PieDatum) => onItemClick('severity', pieSlice.label)}
					isError={!!errorType}
					renderErrorState={() => <ChartErrorView errorType={errorType} />}
				/>
			</ChartWrapper>
		);
	};

	const renderIssuesDistributionPie = (): JSX.Element => {
		const issuesDistributionPieData = pieHelper.getIssuesDistributionPieDataV2(
			orderedSeveritiesData,
			totalIssues
		);

		let errorType: ERROR_TYPE | null = null;

		if (!severitiesData) {
			errorType = ERROR_TYPE.FAILED;
		}
		if (issuesDistributionPieData.length === 0) {
			errorType = ERROR_TYPE.NO_DATA;
		}

		const chartTitle = 'Breakdown by issue type';

		return (
			<ChartWrapper title={`${chartTitle} chart`} className="avoid-break">
				<EvPieChart
					className="issue-distribution"
					data={issuesDistributionPieData}
					title={chartTitle}
					withLegend={false}
					width={!errorType && DEFAULT_CHART_WIDTH}
					height={!errorType && DEFAULT_CHART_HEIGHT}
					withAnimation={false}
					isInteractive={false}
					customTooltip={(context: PieSlice) => {
						return ChartsHelper.renderCustomTooltip(context, issuesDistributionPieData, true);
					}}
					headerTooltipProps={{
						tooltipText:
							'The graph indicates the distribution of issues by type and severity. Each part of the graph represents the % of issues by their type (e.g. Accessible name). The colors represents the severity of the issue type.',
						tooltipTitle: `What is ${chartTitle}`,
						tooltipPlacement: 'right'
					}}
					tableProps={{
						columnsPattern: [
							{
								name: ISSUE_TYPE,
								field: LABEL_FIELD,
								formatter: (cell: string) => fieldFormatter({ key: 'type', value: cell })
							},
							{ name: ISSUES_PERCENTAGE, field: PERCENT_FIELD },
							{ name: TOTAL_ISSUES, field: PAGES_COUNT_FIELD }
						],
						defaultSortByField: 'label'
					}}
					onClick={(pieSlice: PieDatum) => onItemClick('type', pieSlice.label)}
					isError={!!errorType}
					renderErrorState={() => <ChartErrorView errorType={errorType} />}
				/>
			</ChartWrapper>
		);
	};

	return (
		<ChartsSectionV3
			sectionTextBlockTitle="Distribution of issues by type and severity"
			skiplinkId="issues-chart"
			className="issues-charts"
		>
			{severitiesData ? (
				<div className="charts-row">
					{renderSeveritiesDistributionPie()}
					{renderIssuesDistributionPie()}
				</div>
			) : (
				<EvSpinner />
			)}
		</ChartsSectionV3>
	);
};

export { IssuesCharts };
