import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { FormatHelper } from '@evinced-private/ui-common';

import BaseScanViewV2, {
	ScanTableDefinition
} from '../../../../components/scan-analysis/base/BaseScanViewV2';
import { MAX_RESULTS } from '../../../../consts/Results';
import { IIssuesPaginationResponse } from '../../../../interfaces/IssuesPaginationResponse';
import { SummaryItem } from '../../../../interfaces/SummaryItem';
import scanService from '../../../../services/scan/ScanService';
import { FilterParams } from '../../../../types/FilterParams';
import { BreakdownSummary } from '../../../../types/OverviewTypes';
import { PaginationParams } from '../../../../types/PaginationParams';

import ScanBreakdownTableHelper from './ScanBreakdownTableHelperV2';

interface IScanBreakdownView {
	viewId: string;
	scanId: string;
	propertyId: string;
}

const defaultTableSort = ScanBreakdownTableHelper.getDefaultSort();

const ScanBreakdownView: FC<IScanBreakdownView> = ({
	viewId,
	scanId,
	propertyId
}: IScanBreakdownView) => {
	const [totalCount, setTotalCount] = useState<number>(null);
	const [scanResults, setScanResults] = useState<IIssuesPaginationResponse>(null);
	const [summaryPaneInfo, setSummaryPaneInfo] = useState<BreakdownSummary>();
	const screenId = `scan-${scanId}-view-${viewId}-breakdown-view`;

	const loadTableItems = useCallback(
		async (
			scanId: string,
			paginationParams: PaginationParams,
			filtersParams?: FilterParams
		): Promise<void> => {
			const response: IIssuesPaginationResponse = await scanService.getScanIssuesV2(
				scanId,
				paginationParams,
				filtersParams,
				viewId
			);
			setScanResults(response);
		},
		[viewId]
	);

	useEffect(() => {
		if (scanResults?.overallTotal) {
			setTotalCount(scanResults.overallTotal);
		}
	}, [scanResults?.overallTotal]);

	const loadSummaryData = useCallback(async (): Promise<void> => {
		const summaryPaneInfo: BreakdownSummary = await scanService.getAllIssuesSummary(scanId, viewId);
		setSummaryPaneInfo(summaryPaneInfo);
	}, [scanId, viewId]);

	useEffect(() => {
		loadSummaryData();
	}, [loadSummaryData]);

	const summaryPaneData = useMemo((): SummaryItem[] => {
		if (!summaryPaneInfo) {
			return [];
		}
		const { pages, issues, criticalIssues, seriousIssues, components } = summaryPaneInfo;
		return [
			{
				value: FormatHelper.formatNumber(pages),
				label: 'Pages'
			},
			{
				value: FormatHelper.formatNumber(issues),
				label: 'Total issues'
			},
			{
				value: FormatHelper.formatNumber(criticalIssues),
				label: 'Critical issues'
			},
			{
				value: FormatHelper.formatNumber(seriousIssues),
				label: 'Serious issues'
			},
			{
				value: FormatHelper.formatNumber(components),
				label: 'Components'
			}
		];
	}, [summaryPaneInfo, viewId]);

	const tableDefinition = useMemo((): ScanTableDefinition => {
		if (!scanResults) {
			return null;
		}

		const { issues } = scanResults;
		const totalPaginationableResults =
			scanResults.overallTotal > MAX_RESULTS ? MAX_RESULTS : scanResults.overallTotal;
		return ScanBreakdownTableHelper.getTableDefinition(
			issues,
			totalCount,
			totalPaginationableResults
		);
	}, [scanResults, totalCount]);

	return (
		<BaseScanViewV2
			viewId={viewId}
			scanId={scanId}
			screenId={screenId}
			summaryPaneItems={summaryPaneData}
			tableDefinition={tableDefinition}
			defaultTableSort={defaultTableSort}
			loadSummaryData={loadSummaryData}
			loadTableItems={loadTableItems}
			propertyId={propertyId}
			renderCsvExportButton={true}
			tableWithUrlSwitcher={true}
		/>
	);
};

export default ScanBreakdownView;
