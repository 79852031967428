import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import {
	BUTTON_TYPES,
	EvButton,
	EvSection,
	EvSelect,
	EvTextInputSearch,
	SomeItemsSelectedTextFunction,
	TABLE_FILTERS_SR_SUMMARY
} from '@evinced-private/ui-common';

import pick from 'lodash/pick';

import IdFormatterHelper from '../../helpers/IdFormatterHelper';
import { OptionType } from '../../types/OptionType';

import {
	areFiltersEqual,
	FILTER_TYPES,
	getEmptyFilterOptions,
	getVisibleFilterKeys,
	IFilterOptions
} from './DataFilterHelper';

import './DataFilter.scss';

interface IScanDataFilterProps {
	filterOptions: IFilterOptions;
	filterValues: IFilterOptions;
	onApply: (filterValues: IFilterOptions) => void;
	onReset?: () => void;
	textSearchEnabled?: boolean;
	severitiesFilterEnabled?: boolean;
	typesFilterEnabled?: boolean;
	tagsFilterEnabled?: boolean;
	hideLabel?: boolean;
	disableButtons?: boolean;
}

const ScanDataFilter: FC<IScanDataFilterProps> = ({
	filterOptions,
	filterValues,
	onApply,
	onReset,
	textSearchEnabled,
	severitiesFilterEnabled = true,
	typesFilterEnabled = true,
	tagsFilterEnabled = true,
	hideLabel,
	disableButtons
}: IScanDataFilterProps) => {
	const [applyDisabled, setApplyDisabled] = useState<boolean>(false);
	const [resetDisabled, setResetDisabled] = useState<boolean>(false);
	const [currentTypesFilter, setCurrentTypesFilter] = useState<OptionType[]>(filterValues?.types);
	const [currentComponentsFilter, setCurrentComponentsFilter] = useState<OptionType[]>(
		filterValues?.components
	);
	const [currentSeveritiesFilter, setCurrentSeveritiesFilter] = useState<OptionType[]>(
		filterValues?.severities
	);
	const [currentTagsFilter, setCurrentTagsFilter] = useState<OptionType[]>(filterValues?.tags);
	const [currentSearchValueFilter, setCurrentSearchValueFilter] = useState<string>(
		filterValues?.searchValue
	);

	const currentFilters = useMemo(
		() => ({
			components: currentComponentsFilter,
			severities: currentSeveritiesFilter,
			types: currentTypesFilter,
			searchValue: currentSearchValueFilter,
			tags: currentTagsFilter
		}),
		[
			currentComponentsFilter,
			currentSearchValueFilter,
			currentSeveritiesFilter,
			currentTypesFilter,
			currentTagsFilter
		]
	);

	const onFilterChange = useCallback((filters: OptionType[], filterType: FILTER_TYPES): void => {
		if (filterType === FILTER_TYPES.VALIDATION_ID) {
			setCurrentTypesFilter(filters);
		}

		if (filterType === FILTER_TYPES.COMPONENT_ID) {
			setCurrentComponentsFilter(filters);
		}

		if (filterType === FILTER_TYPES.SEVERITY) {
			setCurrentSeveritiesFilter(filters);
		}

		if (filterType === FILTER_TYPES.TAG) {
			setCurrentTagsFilter(filters);
		}
	}, []);

	const onInputChange = useCallback((value: string, filterType: FILTER_TYPES): void => {
		if (filterType === FILTER_TYPES.SEARCH_VALUE_ID) {
			setCurrentSearchValueFilter(value);
		}
	}, []);

	const setButtonState = useCallback(
		(currentValues) => {
			if (filterOptions?.components) {
				return;
			}
			const getVisibleFiltersParams = {
				allOptions: filterOptions,
				textSearchEnabled,
				severitiesFilterEnabled,
				typesFilterEnabled,
				tagsFilterEnabled
			};
			const emptyFilterOptions = getEmptyFilterOptions(getVisibleFiltersParams);
			const visibleFilterValues = pick(
				filterValues,
				...getVisibleFilterKeys(getVisibleFiltersParams)
			);
			const visibleCurrentValues = pick(
				currentValues,
				...getVisibleFilterKeys(getVisibleFiltersParams)
			);
			const stateFilters = visibleFilterValues || emptyFilterOptions;
			const areFiltersDifferentFromAppliedState = areFiltersEqual(
				visibleCurrentValues,
				stateFilters
			);
			const areFiltersDifferentFromInitialState = areFiltersEqual(
				visibleCurrentValues,
				emptyFilterOptions
			);
			setApplyDisabled(areFiltersDifferentFromAppliedState);
			setResetDisabled(areFiltersDifferentFromInitialState && areFiltersDifferentFromAppliedState);
		},
		[
			filterOptions,
			filterValues,
			textSearchEnabled,
			typesFilterEnabled,
			severitiesFilterEnabled,
			tagsFilterEnabled
		]
	);

	const handleReset = useCallback(() => {
		const visibleFilters = getEmptyFilterOptions({
			allOptions: filterOptions,
			textSearchEnabled,
			severitiesFilterEnabled,
			typesFilterEnabled,
			tagsFilterEnabled
		});
		onApply({
			...(filterValues || {}),
			...visibleFilters
		});
		onReset?.();
	}, [
		onApply,
		onReset,
		filterValues,
		filterOptions,
		textSearchEnabled,
		severitiesFilterEnabled,
		typesFilterEnabled,
		tagsFilterEnabled
	]);

	useEffect(() => {
		setButtonState(currentFilters);
	}, [setButtonState, currentFilters]);

	// making sure that the filters display is changed when selected options are passed from parent
	// relevant to reset scenario
	useEffect(() => {
		const initAllFilters = ({
			types,
			components,
			severities,
			searchValue,
			tags
		}: IFilterOptions): void => {
			setCurrentComponentsFilter(components);
			setCurrentSeveritiesFilter(severities);
			setCurrentTypesFilter(types);
			setCurrentTagsFilter(tags);
			setCurrentSearchValueFilter(searchValue);
		};
		if (filterValues || filterOptions) {
			initAllFilters(
				filterValues ||
					getEmptyFilterOptions({
						allOptions: filterOptions,
						textSearchEnabled,
						severitiesFilterEnabled,
						typesFilterEnabled,
						tagsFilterEnabled
					})
			);
		}
	}, [
		filterValues,
		filterOptions,
		textSearchEnabled,
		severitiesFilterEnabled,
		typesFilterEnabled,
		tagsFilterEnabled
	]);

	const renderFilter = useCallback(
		(
			placeHolder: string,
			allSelectedPlaceHolder: string,
			someItemsSelectedText: SomeItemsSelectedTextFunction,
			filterType: FILTER_TYPES,
			options: OptionType[],
			values: OptionType[]
		): JSX.Element => {
			const filterMultiselectId = IdFormatterHelper.formatTextToId(placeHolder);
			return (
				<div className={`scan-data-filter-dropdown ${filterMultiselectId}`}>
					<EvSelect
						id={`${filterMultiselectId}-select`}
						options={options}
						isMulti={true}
						onChange={(filters) => {
							onFilterChange(filters, filterType);
						}}
						allowSelectAll={true}
						isSearchable={true}
						value={values || []}
						placeholder={placeHolder}
						allSelectedPlaceholder={allSelectedPlaceHolder}
						someItemsSelectedText={someItemsSelectedText}
						withMultilineOptions={filterType === FILTER_TYPES.VALIDATION_ID}
					/>
				</div>
			);
		},
		[onFilterChange]
	);

	const typesFilter = useMemo(() => {
		if (!filterOptions?.types || !typesFilterEnabled) {
			return undefined;
		}
		return renderFilter(
			'Select Type',
			'All issue types',
			(numberOfselected: number) => `Type (${numberOfselected})`,
			FILTER_TYPES.VALIDATION_ID,
			filterOptions.types,
			currentTypesFilter
		);
	}, [currentTypesFilter, filterOptions, renderFilter, typesFilterEnabled]);

	const componentFilter = useMemo(() => {
		if (!filterOptions?.components) {
			return null;
		}
		return renderFilter(
			'Select Component',
			'All components',
			(numberOfselected: number) => `Component (${numberOfselected})`,
			FILTER_TYPES.COMPONENT_ID,
			filterOptions.components,
			currentComponentsFilter
		);
	}, [currentComponentsFilter, filterOptions, renderFilter]);

	const severitiesFilter = useMemo((): JSX.Element => {
		if (!filterOptions?.severities || !severitiesFilterEnabled) {
			return undefined;
		}
		return renderFilter(
			'Select Severity',
			'All severities',
			(numberOfselected: number) => `Severity (${numberOfselected})`,
			FILTER_TYPES.SEVERITY,
			filterOptions.severities,
			currentSeveritiesFilter
		);
	}, [currentSeveritiesFilter, filterOptions, renderFilter, severitiesFilterEnabled]);

	const tagsFilter = useMemo((): JSX.Element => {
		if (!tagsFilterEnabled) {
			return undefined;
		}
		return renderFilter(
			'Select Level',
			'Conformance Level',
			(numberOfselected: number) => `Conformance Level (${numberOfselected})`,
			FILTER_TYPES.TAG,
			filterOptions.tags,
			currentTagsFilter
		);
	}, [currentTagsFilter, filterOptions, renderFilter, tagsFilterEnabled]);

	const searchValueFilter = useMemo(() => {
		if (!textSearchEnabled) {
			return null;
		}
		return (
			<div className="search-input">
				<EvTextInputSearch
					value={currentSearchValueFilter}
					onChange={(value: string) => {
						onInputChange(value, FILTER_TYPES.SEARCH_VALUE_ID);
					}}
					onConfirm={() => onApply(currentFilters)}
				/>
			</div>
		);
	}, [textSearchEnabled, currentSearchValueFilter, currentFilters, onApply, onInputChange]);

	const renderSrSummary = (): JSX.Element => {
		const filteredComponents = filterValues?.components?.length || 0;
		const filteredIssueTypes = filterValues?.types?.length || 0;
		const filteredSeverities = filterValues?.severities?.length || 0;
		const filteredTags = filterValues?.tags?.length || 0;
		const allComponents = filterOptions?.components?.length || 0;
		const allIssueTypes = filterOptions?.types?.length || 0;
		const allSeverities = filterOptions?.severities.length || 0;
		const allTags = filterOptions?.tags?.length || 0;

		const componentsText =
			filteredComponents === allComponents || !filterValues?.components
				? 'all'
				: filteredComponents;
		const typesText =
			filteredIssueTypes === allIssueTypes || !filterValues?.types ? 'all' : filteredIssueTypes;
		const severitiesText =
			filteredSeverities === allSeverities || !filterValues?.severities
				? 'all'
				: filteredSeverities;
		const tagsText = filteredTags === allTags || !filterValues?.tags ? 'all' : filteredTags;

		return (
			<div id={TABLE_FILTERS_SR_SUMMARY} style={{ display: 'none' }}>
				Table Filter Results:
				{`components: ${componentsText},`}
				{`issue types: ${typesText},`}
				{`severity levels: ${severitiesText}`}
				{`conformance levels: ${tagsText}`}
			</div>
		);
	};

	return (
		<EvSection className="scan-data-filter" ariaLabel="Table filters">
			{!hideLabel && <label>Filter results</label>}
			{searchValueFilter}
			{severitiesFilter}
			{typesFilter}
			{componentFilter}
			{tagsFilter}
			<EvButton
				type={BUTTON_TYPES.ACTION}
				title="Apply filters"
				onClick={() => onApply(currentFilters)}
				disabled={disableButtons || applyDisabled}
			>
				Apply
			</EvButton>
			<EvButton
				type={BUTTON_TYPES.ACTION}
				title="Reset Filters"
				onClick={handleReset}
				disabled={disableButtons || resetDisabled}
			>
				Reset
			</EvButton>
			{renderSrSummary()}
		</EvSection>
	);
};

export default ScanDataFilter;
