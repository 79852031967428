import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { FormatHelper } from '@evinced-private/ui-common';

import BaseScanViewV2, {
	ScanTableDefinition
} from '../../../../components/scan-analysis/base/BaseScanViewV2';
import { MAX_RESULTS } from '../../../../consts/Results';
import { IRequestedUrlsPaginationResponse } from '../../../../interfaces/RequestedUrlsPaginationResponse';
import { SummaryItem } from '../../../../interfaces/SummaryItem';
import scanService from '../../../../services/scan/ScanService';
import { FilterParams } from '../../../../types/FilterParams';
import { PagesOverviewResponse } from '../../../../types/OverviewTypes';
import { PaginationParams } from '../../../../types/PaginationParams';

import ScanUrlsStatusTableHelperV2 from './ScanUrlsStatusTableHelperV2';

interface IScanUrlsStatusView {
	viewId: string;
	scanId: string;
}

const defaultTableSort = ScanUrlsStatusTableHelperV2.getDefaultSort();

const ScanUrlsStatusView: FC<IScanUrlsStatusView> = ({ scanId, viewId }: IScanUrlsStatusView) => {
	const [scanResults, setScanResults] = useState<IRequestedUrlsPaginationResponse>(null);
	const [totalCount, setTotalCount] = useState<number>(null);
	const [summaryPaneInfo, setSummaryPaneInfo] = useState<PagesOverviewResponse>();

	const screenId = `scan-${scanId}-view-${viewId}-urls-status-view`;
	const loadTableItems = useCallback(
		async (
			scanId: string,
			paginationParams: PaginationParams,
			filtersParams?: FilterParams
		): Promise<void> => {
			const response: IRequestedUrlsPaginationResponse = await scanService.getScanRequestedUrls(
				scanId,
				paginationParams,
				filtersParams,
				viewId
			);
			setScanResults(response);
		},
		[viewId]
	);

	useEffect(() => {
		if (scanResults?.overallTotal) {
			setTotalCount(scanResults.overallTotal);
		}
	}, [scanResults?.overallTotal]);

	const loadSummaryData = useCallback(async (): Promise<void> => {
		const summaryPaneInfo = await scanService.getPagesOverview({ scanId, viewId });
		setSummaryPaneInfo(summaryPaneInfo);
	}, [scanId, viewId]);

	const summaryPaneData = useMemo((): SummaryItem[] => {
		if (!summaryPaneInfo) {
			return [];
		}
		const summary = [
			{
				value: FormatHelper.formatNumber(summaryPaneInfo?.overview.requested),
				label: 'Pages requested'
			},
			{
				value: FormatHelper.formatNumber(summaryPaneInfo?.overview.scanned),
				label: 'Pages scanned'
			},
			{
				value: FormatHelper.formatNumber(summaryPaneInfo?.overview.failed),
				label: 'Pages failed'
			}
		];

		const filteredOut = summaryPaneInfo?.overview.filteredOut;
		if (filteredOut > 0) {
			summary.push({
				value: FormatHelper.formatNumber(filteredOut),
				label: 'Pages duplicated'
			});
		}
		return summary;
	}, [summaryPaneInfo]);

	const tableDefinition = useMemo((): ScanTableDefinition => {
		if (!scanResults) {
			return null;
		}

		const { pages } = scanResults;
		const totalPaginationableResults =
			scanResults.overallTotal > MAX_RESULTS ? MAX_RESULTS : scanResults.overallTotal;

		return ScanUrlsStatusTableHelperV2.getTableDefinition(
			pages,
			totalCount,
			totalPaginationableResults
		);
	}, [scanResults, totalCount]);

	return (
		<BaseScanViewV2
			viewId={viewId}
			scanId={scanId}
			screenId={screenId}
			summaryPaneItems={summaryPaneData}
			tableDefinition={tableDefinition}
			filtersDefinition={{ freeTextSearch: true, types: false, severities: false, tags: false }}
			defaultTableSort={defaultTableSort}
			loadSummaryData={loadSummaryData}
			loadTableItems={loadTableItems}
		/>
	);
};

export default ScanUrlsStatusView;
