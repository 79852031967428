import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { validateProperty } from '../../helpers/FormHelper.ts';
import PropertyHelper from '../../helpers/PropertyHelper.ts';
import PropertySourceHelper from '../../helpers/PropertySourceHelper.ts';
import NotificationsContext from '../../providers/notificationsProvider/NotificationsContext.ts';
import propertiesService from '../../services/PropertiesService.ts';
import JsonPopup from '../json-popup/JsonPopup.tsx';

class CreatePropertyFromJsonPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			errors: []
		};
	}

	onSave = (propertyToSave, close) => {
		const isListMode = PropertySourceHelper.isListMode(propertyToSave?.urlSetConfiguration?.source);
		const formErrors = validateProperty(propertyToSave, isListMode);

		if (formErrors.length) {
			this.setState({ errors: formErrors.map((e) => e.message) });
			return;
		}
		this.setState({ errors: [] });
		this.setState({ isLoading: true }, () => {
			propertiesService
				.createPropertyFromJSON(propertyToSave)
				.then((data) => {
					close();
					this.props.onPropertyCreated(data.id);
				})
				.catch((err) => {
					this.context.alert({ errorMessage: 'Error creating property.', serverError: err });
					this.setState({ isLoading: false });
				});
		});
	};

	render() {
		return (
			<JsonPopup
				title="Create Property"
				initialValue={PropertyHelper.getDefaultPropertyFromTemplate('https://www.example.com')}
				approveButtonText="Save"
				onApprove={this.onSave}
				triggerButtonProps={this.props.triggerButtonProps}
				isLoading={this.state.isLoading}
				errors={this.state.errors}
			/>
		);
	}
}

/* todo: when changing this component to tsx
	 remove contextType and use useAlerts() (look at JsonPopup.tsx example)
	 https://reactjs.org/docs/context.html#dynamic-context
	 */
CreatePropertyFromJsonPopup.contextType = NotificationsContext;

CreatePropertyFromJsonPopup.propTypes = {
	triggerButtonProps: PropTypes.object,
	onPropertyCreated: PropTypes.func
};

export default CreatePropertyFromJsonPopup;
