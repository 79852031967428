import { FC, useEffect } from 'react';

import { useConfiguration } from '../../providers/configurationProvider/ConfigurationProvider';
import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import {
	initUsageAnalyticsService,
	isUsageAnalyticsServiceInitialized,
	sendUserActivityEvent
} from '../../services/analytics/UsageAnalyticsService';
import { SiteScannerUiToggles } from '../../services/LocalTogglesService';

export const UsageAnalyticsInitializer: FC = () => {
	const { getToggle } = useConfiguration();
	const { user, tenant } = useUserTenant();

	useEffect(() => {
		if (!user || !tenant) {
			return;
		}
		if (isUsageAnalyticsServiceInitialized()) {
			return;
		}

		if (getToggle(SiteScannerUiToggles.ENABLE_EVINCED_USAGE_ANALYTICS) !== false) {
			// init
			initUsageAnalyticsService(user.id, tenant.id);

			// send user activity event
			sendUserActivityEvent();
		}
	}, [user, tenant, getToggle]);
	return null;
};
