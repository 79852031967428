import React, { FC, useState } from 'react';

import { EvSpinner, IEvButtonProps } from '@evinced-private/ui-common';

import PropertySourceHelper from '../../helpers/PropertySourceHelper';
import ScanCrawlStateHelper from '../../helpers/ScanCrawlStateHelper';
import { IProperty } from '../../interfaces/Property';
import { useNotifications } from '../../providers/notificationsProvider/NotificationsConsumer';
import logger from '../../services/Logger';
import propertiesService from '../../services/PropertiesService';
import SiteScannerPopup from '../common/site-scanner-popup/SiteScannerPopup';

import './StartCrawlPopup.scss';

interface IStartCrawlPopupProps {
	propertyId: string;
	triggerButtonProps: IEvButtonProps;
	onCrawlCreated: () => void;
}

const StartCrawlPopup: FC<IStartCrawlPopupProps> = ({
	propertyId,
	triggerButtonProps,
	onCrawlCreated
}: IStartCrawlPopupProps) => {
	const [isLoading, setLoading] = useState(true);
	const [property, setProperty] = useState<IProperty>(null);
	const [isCrawlDisabled, setCrawlDisabled] = useState(true);
	const [isCrawlRunning, setCrawlRunning] = useState(false);
	const [isListMode, setIsListMode] = useState<boolean>(false);
	const notificationsContext = useNotifications();

	const getPopupBodyText = (): string => {
		if (isCrawlRunning) {
			return 'There is a crawl running on this property.';
		}
		if (isListMode) {
			return 'The URLs for this property are provided via a list. If you wish to remove the list and crawl the property, go to Settings and change to Crawl mode';
		}
		return 'Do you want to run a crawl on this property?';
	};

	const initCrawlData = async (): Promise<void> => {
		setLoading(true);
		const propertyFromServer = await propertiesService.getProperty(propertyId);
		const crawlRunning = ScanCrawlStateHelper.isInProgress(propertyFromServer?.lastUrlSet?.state);
		const isPropertyInListMode = PropertySourceHelper.isListMode(
			propertyFromServer?.urlSetConfiguration?.source
		);
		setProperty(propertyFromServer);
		setCrawlRunning(crawlRunning);
		setCrawlDisabled(crawlRunning || isPropertyInListMode);
		setIsListMode(isPropertyInListMode);
		setLoading(false);
	};

	const createUrlSetAndUpdateProperty = async (onClose): Promise<void> => {
		setLoading(true);
		setCrawlRunning(true);
		try {
			await propertiesService.createUrlSetAndUpdateProperty(property, isListMode);
			onCrawlCreated();
			onClose();
		} catch (err) {
			const msg = 'Cannot start a new crawl';
			logger.error(msg, err);
			onClose();

			notificationsContext.alert({
				title: msg,
				serverError: err
			});
		} finally {
			setCrawlRunning(false);
		}
	};

	const getTitle = (): string => {
		if (isCrawlDisabled) {
			return 'Cannot crawl property';
		}
		return 'Crawl Now';
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const getButtons = (): any[] => {
		if (isLoading) {
			return [];
		}

		if (isCrawlDisabled) {
			return [
				{
					title: 'OK',
					onClick: (onClose) => {
						onClose();
					}
				}
			];
		}

		return [
			{
				title: 'Crawl',
				disabled: isCrawlDisabled || isLoading,
				loading: isLoading,
				onClick: (onClose) => {
					createUrlSetAndUpdateProperty(onClose);
				}
			}
		];
	};

	const renderBody = (): JSX.Element => {
		if (isLoading) {
			return <EvSpinner />;
		}

		return (
			<div className="body">
				<span className="popup-message">{getPopupBodyText()}</span>
			</div>
		);
	};

	return (
		<SiteScannerPopup
			title={getTitle()}
			isSrOnly={isLoading}
			isCentered
			triggerButtonProps={triggerButtonProps}
			onOpen={initCrawlData}
			buttons={getButtons()}
		>
			{renderBody()}
		</SiteScannerPopup>
	);
};

export default StartCrawlPopup;
