import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

import { FieldOptionName } from '../../interfaces/Scan';
import SeverityService from '../../services/severities/SeverityService';
import { OptionType } from '../../types/OptionType';

export enum FILTER_TYPES {
	SEARCH_VALUE_ID,
	COMPONENT_ID,
	VALIDATION_ID,
	SEVERITY,
	TAG
}

export interface IFilterOptions {
	components?: OptionType[];
	types?: OptionType[];
	severities?: OptionType[];
	searchValue?: string;
	urls?: OptionType[];
	tags?: OptionType[];
}

const emptyFilterOptions: IFilterOptions = {
	types: [],
	severities: [],
	searchValue: '',
	components: [],
	urls: [],
	tags: []
};

// get only those filter types which are gonna be visible on the UI
export const getVisibleFilterKeys = ({
	allOptions,
	textSearchEnabled,
	severitiesFilterEnabled = true,
	typesFilterEnabled = true,
	tagsFilterEnabled = true
}: {
	allOptions: IFilterOptions;
	textSearchEnabled?: boolean;
	severitiesFilterEnabled?: boolean;
	typesFilterEnabled?: boolean;
	tagsFilterEnabled?: boolean;
}): string[] => {
	const keys = Object.keys(allOptions ?? {}).filter((key) => {
		if (key === 'searchValue') {
			return textSearchEnabled;
		}

		if (key === 'severities' && !severitiesFilterEnabled) {
			return false;
		}

		if (key === 'types' && !typesFilterEnabled) {
			return false;
		}

		if (key === 'tags' && !tagsFilterEnabled) {
			return false;
		}

		return !!allOptions[key]?.length;
	});

	// the most reliable way to make sure we have the searchValue key regardless of
	// allOptions from the backend because text search is only controlled by textSearchEnabled
	if (textSearchEnabled && !keys.includes('searchValue')) {
		keys.push('searchValue');
	}

	return keys;
};

export const getEmptyFilterOptions = (params: {
	allOptions: IFilterOptions;
	textSearchEnabled?: boolean;
	severitiesFilterEnabled?: boolean;
	typesFilterEnabled?: boolean;
	tagsFilterEnabled?: boolean;
}): IFilterOptions => pick(emptyFilterOptions, ...getVisibleFilterKeys(params));

export const orderByMethod = (values: string[] = [], option = ''): string[] => {
	if (option === FieldOptionName.SEVERITY) {
		return SeverityService.sortSeverities(values);
	}
	return values.sort();
};

const getArrayValues = (arr: OptionType[]): string[] => arr.map((option) => option.value);

const clearSelectAll = (arr: string[] = []): string[] => {
	return arr.filter((val) => val !== '*');
};

const isFilterEqual = (current: OptionType[], state: OptionType[]): boolean => {
	if (typeof current !== typeof state) {
		return false;
	}
	if (!current && !state) {
		return true;
	}
	if (typeof current === 'string') {
		return current === state;
	}
	const currentArr = clearSelectAll(getArrayValues(current)).sort();
	const stateArr = clearSelectAll(getArrayValues(state)).sort();
	return isEqual(currentArr, stateArr);
};

export const areFiltersEqual = (current: IFilterOptions, state: IFilterOptions): boolean =>
	Object.keys(current).every((filter) => isFilterEqual(current?.[filter], state?.[filter]));

const getAppliedFiltersInitialValue = (
	allFilters: IFilterOptions,
	selectedType: string,
	selectedSeverity: string,
	textSearchEnabled?: boolean,
	severitiesFilterEnabled?: boolean,
	typesFilterEnabled?: boolean,
	tagsFilterEnabled?: boolean
): IFilterOptions => {
	const filters = getEmptyFilterOptions({
		allOptions: allFilters,
		textSearchEnabled,
		severitiesFilterEnabled,
		typesFilterEnabled,
		tagsFilterEnabled
	});

	if (selectedType) {
		filters.types = [allFilters?.types.find((option) => option.value === selectedType)];
	}
	if (selectedSeverity) {
		filters.severities = [
			allFilters?.severities.find((option) => option.value === selectedSeverity)
		];
	}
	return filters;
};

export default {
	orderByMethod,
	areFiltersEqual,
	getAppliedFiltersInitialValue,
	getVisibleFilterKeys
};
